import * as React from 'react';

import { PriceConfigKind } from 'app2/api';
import { Box, Section } from 'app2/components';

import { EnrollmentBreakdownProps, EnrollmentBreakdown } from './EnrollmentBreakdown';
import { EnrollmentDiscountCombo } from './EnrollmentDiscountForm';

export function DiscountComboAndBreakdown(props:EnrollmentBreakdownProps) {
  return props.parentCourse.behavior.enrollmentAddWithDiscount
    ? <Section label="Adjust price" width="100%">
      <Box layout={['vbox', 'hbox']} width="100%" vAlign={[undefined, 'top']} hItemSpace={[undefined, '$4']}>
        <EnrollmentDiscountCombo disableFixed={props.config?.kind == PriceConfigKind.Usage} />
        <EnrollmentBreakdown {...props} />
      </Box>
    </Section>
  : <EnrollmentBreakdown {...props} />
}
