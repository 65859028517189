import * as React from 'react';
import pluralize from 'pluralize';
import { commaListsAnd } from 'common-tags';
import { GraphQLError } from 'graphql';

import { EnrollmentUtils, Enrollment, PriceConfigKind, ErrorWithStringPath, EnrollmentInput, EnrollmentWithStudentName } from 'app2/api';
import { Modal, RepeatingSection, Form, FormModel, Info, Field, useForm, useSafeState, NotificationManager, Collapsible, VBox, Text, Ul, Li } from 'app2/components';
import { EnrollmentConfigurationForm, EnrollmentForm, getEnrollmentConfig } from 'app2/views/shared-public';

import { removePathSegment } from '../../../../error';

import { CourseSelections } from '../../../generated';

import { DistinctEnrollmentsSelections } from '../../enrolled';

import { EnrollmentBreakdown } from '../price-config';

import { customCharge, CustomChargeMutation, CustomChargeMutationVariables } from './generated';

type ResultEnrollment = CustomChargeMutation['enrollmentsCustomCharge']['enrollments'][0];

interface FormEnrollment {
  student: string;
  studentId: string;
}

interface FormValues extends EnrollmentForm {
  enrollments: FormEnrollment[];
}

type CustomChargeForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: DistinctEnrollmentsSelections[];
}

export function CustomCharge(props: Props) {
  const form = useForm<FormValues>(
    {
      id: props.course.id,
      custom: { amount: null, description: '' },
      enrollments: props.enrollments.map(e => ({
        student: EnrollmentUtils.getStudentName((e as unknown) as Enrollment),
        studentId: e.student.id
      }))
    },
    [props.course],
    { alwaysSave: true }
  );
  const config = getEnrollmentConfig(form);
  const [errors, setErrors] = useSafeState<GraphQLError[]>([]);

  function render() {
    return (
      <Modal title="Charge extra" cancel={errors.length ? null : undefined} ok={errors.length ? 'Continue' : 'Charge'}>
        {renderForm()}
        {renderErrors()}
      </Modal>
    );
  }

  function renderForm() {
    if (errors.length) {
      return null;
    }

    return (
      <Form form={form} onOk={handleSubmit} onNavigation="nothing">
        <RepeatingSection name="enrollments" bordered fields={[<Field label="Student" name="student" />]} />
        <EnrollmentConfigurationForm course={props.course} form={form} kind={PriceConfigKind.Custom} />
        <Collapsible type="box" label="See charge details">
          <EnrollmentBreakdown parentCourse={props.course} course={props.course} config={config} vendorFeeLabel="Amount (before fees)" />
        </Collapsible>
        {renderWarnings()}
      </Form>
    );
  }

  function renderErrors() {
    if (!errors.length) {
      return null;
    }

    const paymentMethodErrors = errors.filter(e => ['CARD_ERROR', 'ACH_ERROR'].includes(e.extensions.code as string));
    const paymentMethodMissingErrors = errors.filter(e => e.extensions.code === 'NO_PAYMENT_METHOD');
    const unknownErrors = errors.filter(e => !['CARD_ERROR', 'ACH_ERROR', 'NO_PAYMENT_METHOD'].includes(e.extensions.code as string));

    return (
      <Info type="warning">
        {renderErrorSection(paymentMethodErrors, "Some families weren't charged due to insufficient funds or missing payment information. Please reach out to the following families or try again later:")}
        {renderErrorSection(paymentMethodMissingErrors, "Some families weren't charged because they don't have a payment method on file. Please reach out to the following families:")}
        {renderErrorSection(unknownErrors, "Some families weren't charged due to an unknown error. Please reach out to Homeroom support or try again later:")}
      </Info>
    );
  }

  function renderErrorSection(errors: GraphQLError[], message: string) {
    return errors.length ? (
      <VBox mb="$16">
        <Text mb="$8">{message}</Text>
        <Ul>
          {errors.map(e => (
            <Li key={e.message}>{getStudentNameFromError(e)}</Li>
          ))}
        </Ul>
      </VBox>
    ) : null;
  }

  function getStudentNameFromError(e: GraphQLError) {
    const enrollment = props.enrollments.find(enr => enr.student.id === (e.extensions.path as string[])[1]);
    return EnrollmentUtils.getStudentName((enrollment as unknown) as EnrollmentWithStudentName);
  }

  function renderWarnings() {
    return <Info type="warning">Students' families will be charged immediately if they have a payment method on file.</Info>;
  }

  async function handleSubmit(form: CustomChargeForm) {
    const custom = form.values.custom;
    const enrollments = props.enrollments.map(e => ({ id: props.course.id, kind: PriceConfigKind.Custom, custom, studentId: e.student.id }));
    const [success, res] = await customCharge({
      variables: { enrollments },
      error: {
        handler: form,
        transform: [idToIndex, removePathSegment('studentId')]
      }
    });

    const succeeded = (res.data?.enrollmentsCustomCharge?.enrollments || []) as ResultEnrollment[];
    const errors = res.error?.graphQLErrors || []; // { extensions { code, path }, message }

    if (succeeded.length) {
      const message = `${commaListsAnd`${props.enrollments
        .filter(e => succeeded.find(s => e.student.id == s.student.id))
        .map(e => EnrollmentUtils.getStudentName((e as unknown) as EnrollmentWithStudentName))}`} ${pluralize('was', succeeded.length)} successfully charged.`;
      NotificationManager.add({ type: 'success', message, duration: 10000 });
    }

    setErrors(errors);

    return success;
  }

  return render();
}

// transforms an error path w/ an identifier, e.g. "enrollments.83257.studentId", to a path to the formm field, e.g. "enrollments.0.studentId". 83257 is the student id, and 0 is the index of the student in the form.
function idToIndex(error: ErrorWithStringPath, input: CustomChargeMutationVariables): ErrorWithStringPath {
  const variables = input;
  const id = error.path[1];
  const pos = (variables.enrollments as EnrollmentInput[]).findIndex(e => e.studentId === id);

  const err = { ...error };
  if (pos === undefined) {
    return err;
  }

  err.path[1] = pos.toString();

  return error;
}
