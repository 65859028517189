import * as React from 'react'
import { Redirect } from 'react-router-dom';

import { Box, ExternalHtml, hasAppMode } from 'app2/components'
import { PublicPage, useCurrentUser } from 'app2/views/shared-public'

export function Home() {
  const { user, fetching, loading } = useCurrentUser();

  if (!hasAppMode('embed') && user && !fetching && !loading) {
    if (user.roles.admin) {
      return <Redirect to='/admin2' />
    }

    if (user.roles.organizer) {
      return <Redirect to='/organizer' />
    }

    if (user.roles.provider) {
      return <Redirect to='/provider' />
    }

    if (user.roles.parent) {
      return <Redirect to='/family' />
    }

    if (user.roles.course) {
      return <Redirect to='/instructor' />
    }

    return <Redirect to='/add-role' />
  }

  // if you get updated assets for the home page:
  //
  // 1) always place in a folder called html under this folder (webpack.config.base.js
  // copyConfig referst to this, as well as template.html refers to it)
  // 
  // 2) rename index.html to home.html
  //
  // 3) ts-node build/home-page/split-html.ts src/app2/views/public/home/html/home.html

  // template.html is setup to have the home page embedded in it for performance
  // reasons.  this code will look and see if that home page is already in the
  // current html.  if so, it will reposition that node to be underneath the app
  // node (so that the header shows up).  else if will render ExternalHtml tag
  // which will load it.
  //
  // if the page is not home, then app/index will remove the static-home node

  const staticHome = document.getElementById('static-home');
  const root = document.getElementById('root');
  const reposition = staticHome && root && root.contains(staticHome);
  const children = staticHome ? null : <ExternalHtml src='/home-body.html' width='100%' height='100%' />

  React.useEffect(() => {
    if (staticHome) {
      window.document.getElementById('app-home')?.appendChild(staticHome);
    }
  }, [reposition]);

    const css:any = {
      height: '100%', 
      width: '100%', 
      overflow: 'auto', 
      // undo the removal our app does
      'ul, ol':{
        'list-style': 'initial'
      }
    }

  return <PublicPage hAlign='center' pt={null} scroller={false}>
    <Box id='app-home' css={css}>{children}</Box>
  </PublicPage>
}
