import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentBilledLaterQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentBilledLaterQuery = {
  user?:
    | {
        billedLater?:
          | Array<
              | {
                  id: string;
                  status: Types.EnrollmentStatusEnum;
                  waitlistKind: Types.WaitlistKind;
                  rosterPeriod?: string | null | undefined;
                  rosterAmount?: string | null | undefined;
                  billPeriod?: string | null | undefined;
                  startDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  withdrawable?: boolean | null | undefined;
                  student?: { id: string; name: string } | null | undefined;
                  priceConfig?: { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | null | undefined;
                  course: { id: string; name?: string | null | undefined; room?: string | null | undefined };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentBilledLaterDocument = gql`
  query ParentBilledLater {
    user: currentUser {
      billedLater: enrollments(queries: ["BILLED_LATER", "PRESENT"]) {
        id
        status
        waitlistKind
        student {
          id
          name
        }
        rosterPeriod
        rosterAmount
        billPeriod
        startDate
        endDate
        withdrawable
        priceConfig {
          kind
        }
        course {
          id
          name
          room
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentBilledLaterArgs = MakeOptional<UseQueryArgs<ParentBilledLaterQueryVariables, ParentBilledLaterQuery>, 'query'>;

export function useParentBilledLaterQuery(options: ParentBilledLaterArgs = {}) {
  return useQuery<ParentBilledLaterQueryVariables, ParentBilledLaterQuery>({ query: ParentBilledLaterDocument, ...options });
}

export type ParentBilledLaterOptions = ExecQueryOptions<ParentBilledLaterQueryVariables>;

export function parentBilledLater(options: ParentBilledLaterOptions) {
  return executeQuery<ParentBilledLaterQuery, ParentBilledLaterQueryVariables>(ParentBilledLaterDocument, options);
}

export type ParentBilledLaterSelections = ParentBilledLaterQuery['user'];
