import * as React from 'react';
import { Location } from 'history';
import { useLocation } from 'react-router';
import { last } from 'lodash-es';
import moment from 'moment';

import { Company, Site, PaymentService } from 'app2/api';
import { arrayToString, BooleanLabelField, CurrencyField, DateInput, DataTableColumn, DataTableColumnSort, DataTableFilterType, DateField, FieldInfo, Link, Option, Text, VBox } from 'app2/components';
import { CourseStatusTag, GradeField, MultipleGradeField } from 'app2/views/shared-public';
import { PaymentStatusTag, StudentModal, StudentNameField, paymentServiceLabels, GetFilterOptions } from 'app2/views/shared';

import { PayoutStatusTag } from './PayoutStatusTag';

interface Props<T> {
  usesSeason?:boolean;
  season?:{id:string, name:string};
  depositDates?:DateInput[];
  isSite?:boolean;
  getFilterOptions:GetFilterOptions<T>;
  entity?:Partial<Site | Company>;
}

export function useReportCols<T>(props:Props<T>) {
  const location = useLocation();

  return React.useMemo(() => getReportCols(props, location), [props.entity, props.season, props.depositDates]);
}

export function getReportCols<T>(props:Props<T>, location:Location) {
  const {usesSeason, season, depositDates, isSite, getFilterOptions, entity} = props;

  if (usesSeason && !season) {
    return [];
  }

  const common = {
    readOnly:true,
    width: 175
  }

  const commonSortFilter = {
    sortable:true,
    filterable:true,
    getFilterOptions: getFilterOptions as GetFilterOptions<T>
  }

  const commonDate = {
    ...common,
    ...commonSortFilter,
    ...DateField,
    filterType: 'date-range' as DataTableFilterType
  }

  const commonDateTime = {
    ...commonDate,
    timezone: entity?.timezone,
  }

  const commonCurrency = {
    ...common,
    ...commonSortFilter,
    ...CurrencyField,
  }

  const coursesTransactionsUrl = getCourseTransactionUrl(location);
  const haveDespositFilter = depositDates?.length == 2;

  const cols:Array<DataTableColumn<T>> = [
    {
      ...commonDateTime,
      name: 'transactionDate' as keyof T,
      label: 'Transaction date',
      sort: DataTableColumnSort.descending,
    },
    {
      ...commonCurrency,
      name: 'transactionAmount' as keyof T,
      label: 'Amount',
      hidden: !isSite
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'transactionStatus' as keyof T,
      label: 'Status',
      component: PaymentStatusTag
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'paymentService' as keyof T,
      label: 'Payment method',
      format: (value:PaymentService) => paymentServiceLabels[value]
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'transactionDescription' as keyof T,
      label: 'Transaction',
    },
    {
      ...common,
      name: 'transactionNote' as keyof T,
      label: 'Note',
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'site.name' as keyof T,
      label: 'School',
      filter: haveDespositFilter || !isSite ? undefined : [props.entity?.name]
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'season.name' as keyof T,
      label: 'Season',
      filter: haveDespositFilter || !usesSeason ? undefined : [season?.name]
    },
    {
      ...common,
      // cant be sort/filtered due to it being calculated on the server
      name: 'season.formattedKind' as keyof T,
      label: 'Activity type'
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'course.name' as keyof T,
      label: 'Activity',
      format: (name:string, _:any, info:FieldInfo) => {
        return coursesTransactionsUrl ? <Link to={coursesTransactionsUrl + info.record.id}>{name}</Link> : name;
      }
    },
    {
      ...commonDate,
      name: 'course.startDate' as keyof T,
      label: 'Activity start'
    },
    {
      ...commonDate,
      name: 'course.endDate' as keyof T,
      label: 'Activity end'
    },
    {
      // current cant sort/fitler on status do it being named
      // cached_status on the server in the gql type
      ...common,
      name: 'course.status' as keyof T,
      label: 'Status',
      display: { component: CourseStatusTag, small: true },
    },
    {
      ...common,
      name: 'course.classesCount' as keyof T,
      label: 'Class count',
      hidden: !isSite
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'company.name' as keyof T,
      label: 'Provider'
    },
    {
      ...common,
      ...MultipleGradeField,
      name: 'course.grades' as keyof T,
      label: 'Grades'
    },
    {
      name: 'billPeriod' as keyof T,
      label: 'Billing period',
    },
    {
      ...common,
      name: 'rosterPeriod' as keyof T,
      label: 'Schedule'
    },
    {
      ...commonCurrency,
      name: 'listPrice' as keyof T,
      label: 'List price',
      hidden: !isSite
    },
    {
      ...commonCurrency,
      name: 'discountAmount' as keyof T,
      label: 'Discount',
    },
    {
      ...commonSortFilter,
      name: 'discountCodes' as keyof T,
      label: 'Discount codes',
      display: { format: arrayToString }
    },
    {
      // current companyFee relies on breakdown which
      // is a json prop and we don't support aliases to
      // json props so we can't sort/filter at the db level yet
      ...CurrencyField,
      name: 'companyFee' as keyof T,
      label: 'Provider activity fee',
    },
    {
      ...commonCurrency,
      name: 'companyProcessingFee' as keyof T,
      label: 'Provider payment processing',
    },
    {
      ...commonCurrency,
      name: 'companyEarnings' as keyof T,
      label: 'Provider earnings',
    },
    {
      ...commonDate,
      name: 'companyExternalTransferEstimatedDate' as keyof T,
      label: 'Provider deposit date',
      none: false,
      infoTip: 'Dates on pending payouts are estimates',
      filterName: 'companyExternalTransferDate', 
      filter: haveDespositFilter ? depositDates : undefined
    },
    {
      ...commonCurrency,
      name: 'companyExternalTransferAmount' as keyof T,
      label: 'Provider deposit amount'
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'companyExternalTransferStatus' as keyof T,
      label: 'Provider deposit status',
      component: PayoutStatusTag
    },
    {
      // cant be sort/filtered due to how its defined in the gql type
      ...CurrencyField,
      name: 'siteFee' as keyof T,
      label: 'School fee',
      hidden: !isSite,
    },
    {
      ...commonCurrency,
      name: 'siteEarnings' as keyof T,
      label: 'School earnings',
      hidden: !isSite,
    },
    {
      ...commonCurrency,
      name: 'siteProcessingFee' as keyof T,
      label: 'School payment processing',
      hidden: !isSite,
    },
    {
      ...commonDate,
      name: 'siteExternalTransferEstimatedDate' as keyof T,
      filterName: 'siteExternalTransferDate', 
      label: 'School deposit date',
      none: false,
      filter: haveDespositFilter ? depositDates : undefined,
      infoTip: 'Dates on pending payouts are estimates',
      hidden: !isSite,
    },
    {
      ...commonCurrency,
      name: 'siteExternalTransferAmount' as keyof T,
      label: 'School deposit amount',
      hidden: !isSite,
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'siteExternalTransferStatus' as keyof T,
      label: 'School deposit status',
      hidden: !isSite,
      component: PayoutStatusTag
    },
    {
      ...common,
      ...BooleanLabelField,
      name: 'coversProcessingFee' as keyof T,
      label: 'Family covers payment processing',
      hidden: !isSite,
    },
    ((entity as Site)?.usingPriceTiers) && {
      ...common,
      name: 'enrollmentPriceTierUse.cartPriceTierUse.name' as keyof T,
      label: 'Price tier',
      component: CurrencyField,
      hidden: !isSite,
    },
    {
      ...common,
      name: 'student.nameWithNickname' as keyof T,
      label: 'Student',
      component: StudentNameField,
      nameKind:'full',
      studentModal: StudentModal
    },
    {
      ...common,
      ...GradeField,
      name: 'student.grade' as keyof T,
      label: 'Student grade',

    },
    {
      ...common,
      name: 'parent.name' as keyof T,
      label: 'Family name'
    },
    {
      ...common,
      ...commonSortFilter,
      name: 'parent.email' as keyof T,
      label: 'Family email'
    },
    {
      ...common,
      name: 'trackingParameters' as keyof T,
      label: 'Tracking',
      hidden: !(props.entity as Site)?.usingTrackingParameters
    }
  ];
  
  return cols.filter(c => c);
}

// this assumes the that courses transactions url can always be determined
// by taking the current url and removing /last-part/course-id?
// so if we are in the overview report, we remove overview, if we are 
// in the transactions report we remove /transactions and if we are in
// a course transactions reprot we remove /transactions/course-id

function getCourseTransactionUrl(location:Location) {
  const pathParts = location.pathname.split('/');

  if (last(pathParts).length == 0) {
    pathParts.pop();
  }

  pathParts.pop();

  if (Number(last(pathParts)) > 0) {
    pathParts.pop();
  }

  const coursesTransactionsUrl = pathParts.join('/') + '/transactions/';

  return coursesTransactionsUrl;
}
