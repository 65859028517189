import * as React from 'react';

import { DiscountDefinitionFormat } from 'app2/api';
import { Dropdown, VBox, BoxProps, Combobox, useFormInfo, FieldProps, CurrencyInput, PercentInput, Field, floatParser, FieldInfo, formatCurrency, formatPercent, useFormSubscription } from 'app2/components';

type Form<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum> = {
  [K in RateType]: RateTypeEnum } & {
  [K in RateValue]: number
};

interface Props<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum> extends BoxProps {
  swap?:boolean;
  disableFixed?:boolean;
  value?:Form<RateType, RateValue, RateTypeEnum>;
}

export function createRateCombo<RateType extends PropertyKey, RateValue extends PropertyKey, RateTypeEnum>(RATE_TYPE:RateType, RATE_VALUE:RateValue, RATE_FIXED:RateTypeEnum, RATE_PERCENTAGE:RateTypeEnum, noneOption:boolean) {
  const rateOptions = [{
      label: 'Fixed amount',
      value: RATE_FIXED
    }, {
      label: 'Percentage',
      value: RATE_PERCENTAGE
    }
  ];

  if (noneOption) {
    rateOptions.unshift({
      label: 'None',
      value: null
    })
  }

  const rateTypeField = {
    name: RATE_TYPE,
    edit: { component: Dropdown, options: rateOptions }
  }

  const rateValueField = {
    name: RATE_VALUE,
    display: { 
      format: (rateValue:any, _field: FieldProps<Form<RateType, RateValue, RateTypeEnum>, any, any>, info: FieldInfo<Form<RateType, RateValue, RateTypeEnum>>) => {
        const percent = info.record?.[RATE_TYPE] == RATE_PERCENTAGE;
        const formatter = percent ? formatPercent : formatCurrency;

        return formatter(rateValue);
      }
    }
  }

  const RateCombo = function(props:Props<RateType, RateValue, RateTypeEnum>) {
    const { swap, value, ...remaining } = props;
    const formInfo = useFormInfo<Form<RateType, RateValue, RateTypeEnum>>();
    const form = formInfo.form;
    const options = rateOptions.filter(o => props.disableFixed && o.value == RATE_FIXED ? false : true);

    useFormSubscription({ field: 'format' });

    function render() {
      return <VBox width="100%" {...remaining}>
        {formInfo.editing ? renderEdit() : renderDisplay()}
      </VBox>
    }

    function renderDisplay() {
      return <Field {...rateValueField} />;
    }

    function renderEdit() {
      return <Combobox mb="$4" flex={1} swap={swap} dropdown={<Field {...{...rateTypeField, options }} onChange={handleFormatChange} />} input={<Field {...rateValueField} component={renderInput()} parse={floatParser} />} />
    }

    function renderInput() {
      const type = formInfo.form.getValue(formInfo.parents, RATE_TYPE);
      const disabled = !type;
      const percent = type == RATE_PERCENTAGE;

      return percent ? <PercentInput disabled={disabled} /> : <CurrencyInput disabled={disabled} min={0} max={9999} />
    }

    function handleFormatChange(value:RateTypeEnum, _info: FieldInfo<Form<RateType, RateValue, RateTypeEnum>>) {
      form.setValue(formInfo.parents, RATE_VALUE, (!value ? null : 0) as any);
    }

    return render();
  }

  return RateCombo;
}
  
export interface DiscountForm {
  format:DiscountDefinitionFormat;
  amount:number;
}

export const DiscountCombo = createRateCombo<'format', 'amount', DiscountDefinitionFormat>('format', 'amount', DiscountDefinitionFormat.Fixed, DiscountDefinitionFormat.Percentage, false);
